//===================================================
//	font setting
//===================================================
//font-size
$f-sp:14; //スマホ用フォントサイズ
$f-tb:15; //タブレット用フォントサイズ
$f-pc:17; //PC用フォントサイズ


//font-family
$font-set:(
	font01:"'Noto Sans JP'", //メインフォントファミリー
	font02:"'Gantari'", //英字フォントファミリー
	font03:"'Akshar'", //その他ファミリー
);


/* webfont 変数　*/
$icons: (
  pagetop: "\e900",
  document: "\e901",
  down_arrow: "\e313",
  left_arrow: "\e314",
  right_arrow: "\e315",
  top_arrow: "\e316",
  link: "\e902",
  tag: "\e903",
  check: "\e904",
  search: "\e905",
  search-plus: "\e908",
  phone: "\e909",
  home: "\e90b",
  pin: "\e90e",
  mail: "\e911",
  chevron-right: "\e906",
  pdf: "\e907"
) !default;

@mixin icon($icon: false, $position:before) {
  @if $position == both {
    $position: ":before,:after";
  }
  &:#{$position} {
    @if $icon {
      content: "#{map-get($icons, $icon)}";
      font-family: "icomoon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  @content;
}