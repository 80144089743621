@charset "utf-8";
@import "base";
/* CSS Document */
/*====================================================================

	scroll animation

=====================================================================*/

/* fade-in */
.fade-in{
  &.trigger--off,
  .trigger--off &{
    opacity: 0;
  }
  &.trigger--on,
  .trigger--on &{
    transition: all .8s cubic-bezier(.2,.24,.7,.69);
    opacity: 1;
  }
}

.fade-up{
  &.trigger--off,
  .trigger--off &{
    opacity: 0;
    transform: translateY(70px);
  }
  &.trigger--on,
  .trigger--on &{
    transition: all .6s cubic-bezier(.43,.03,.7,.97);
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-left{
  &.trigger--off,
  .trigger--off &{
    opacity: 0;
    transform: translatex(-100px);
  }
  &.trigger--on,
  .trigger--on &{
    transition: all .7s cubic-bezier(.16,.25,.7,.97);
    opacity: 1;
    transform: translatex(0);
  }
}

.fade-right{
  &.trigger--off,
  .trigger--off &{
    opacity: 0;
    transform: translatex(100px);
  }
  &.trigger--on,
  .trigger--on &{
    transition: all .7s cubic-bezier(.16,.25,.7,.97);
    opacity: 1;
    transform: translatex(0);
  }
}